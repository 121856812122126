import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import "./TechStacksMobile.scss"
import * as styles from "./TechStacksMobile.module.scss"

const TechStacksMobile = ({ strapiData }) => {
  const data = [
    {
      title: "CMS Platforms",
      tech: [
        {
          name: "Shopify",
          icon: "https://invozone-backend.s3.amazonaws.com/shopify_2634b929db.svg",
        },
        {
          name: "Web Flow",
          icon: "https://invozone-backend.s3.amazonaws.com/webflow_a26c5101d4.svg",
        },
        {
          name: "Wix",
          icon: "https://invozone-backend.s3.amazonaws.com/wix_52cac9f820.svg",
        },
        {
          name: "Drupal",
          icon: "https://invozone-backend.s3.amazonaws.com/drupal_491b88e8c1.svg",
        },
        {
          name: "Joomla",
          icon: "https://invozone-backend.s3.amazonaws.com/joomla_01ef51ff3d.svg",
        },

        {
          name: "Wordpress",
          icon: "https://invozone-backend.s3.amazonaws.com/wordpress_d16bc7fda9.svg",
        },
        {
          name: "Woo Commerce",
          icon: "https://invozone-backend.s3.amazonaws.com/woo_commerce_615276ab82.svg",
        },
      ],
    },
    {
      title: "Frontend",
      tech: [
        {
          name: "HTML",
          icon: "https://invozone-backend.s3.amazonaws.com/html_b98b41dd3a.svg",
        },
        {
          name: "CSS",
          icon: "https://invozone-backend.s3.amazonaws.com/css_23fa4ecec3.svg",
        },
        {
          name: "React",
          icon: "https://invozone-backend.s3.amazonaws.com/react_js_876c28a7f6.svg",
        },
        {
          name: "Angular",
          icon: "https://invozone-backend.s3.amazonaws.com/angular_accf40208e.svg",
        },
        {
          name: "Vue",
          icon: "https://invozone-backend.s3.amazonaws.com/vue_fc7995e57d.svg",
        },
        {
          name: "Bootstrap",
          icon: "https://invozone-backend.s3.amazonaws.com/bootstrap_bb20df2e8a.svg",
        },
      ],
    },
    {
      title: "Backend",
      tech: [
        {
          name: "PHP",
          icon: "https://invozone-backend.s3.amazonaws.com/php_3b9d0bee83.svg",
        },
        {
          name: "Node.js",
          icon: "https://invozone-backend.s3.amazonaws.com/node_js_6024302d7b.svg",
        },
        {
          name: ".NET Stack",
          icon: "https://invozone-backend.s3.amazonaws.com/dot_net_stack_71387c1256.svg",
        },
        {
          name: "Express.js",
          icon: "https://invozone-backend.s3.amazonaws.com/express_js_ff7dc616c5.svg",
        },
        {
          name: "Wordpress",
          icon: "https://invozone-backend.s3.amazonaws.com/wordpress_d16bc7fda9.svg",
        },
        {
          name: "Java",
          icon: "https://invozone-backend.s3.amazonaws.com/java_e988ada8c2.svg",
        },
        {
          name: "Ruby",
          icon: "https://invozone-backend.s3.amazonaws.com/ruby_2448fbb95f.svg",
        },
        {
          name: "Python",
          icon: "https://invozone-backend.s3.amazonaws.com/python_cd0a572d93.svg",
        },
      ],
    },
    {
      title: "Testing Tools",
      tech: [
        {
          name: "Postman",
          icon: "https://invozone-backend.s3.amazonaws.com/postman_531dd20323.svg",
        },
        {
          name: "Jmeter",
          icon: "https://invozone-backend.s3.amazonaws.com/jmeter_2b862ac481.svg",
        },
        {
          name: "Selenium",
          icon: "https://invozone-backend.s3.amazonaws.com/selenium_ecde5053d2.svg",
        },
        {
          name: "Cypress",
          icon: "https://invozone-backend.s3.amazonaws.com/cypress_216f4b3135.svg",
        },
        {
          name: "BrowserStack",
          icon: "https://invozone-backend.s3.amazonaws.com/browserstack_858d0150a1.svg",
        },
        {
          name: "Bugzilla",
          icon: "https://invozone-backend.s3.amazonaws.com/bugzilla_2760fd8704.svg",
        },
        {
          name: "SoapUI",
          icon: "https://invozone-backend.s3.amazonaws.com/soapui_fde55b50e3.svg",
        },
        {
          name: "Karma",
          icon: "https://invozone-backend.s3.amazonaws.com/karma_c5e86ed042.svg",
        },
        {
          name: "Jasmine",
          icon: "https://invozone-backend.s3.amazonaws.com/jasmine_b9be38fa6b.svg",
        },
      ],
    },
    {
      title: "DevOps",
      tech: [
        {
          name: "MySQL",
          icon: "https://invozone-backend.s3.amazonaws.com/mysql_661f6dbd15.svg",
        },
        {
          name: "Linux",
          icon: "https://invozone-backend.s3.amazonaws.com/linux_4beca29b92.svg",
        },
        {
          name: "Apache",
          icon: "https://invozone-backend.s3.amazonaws.com/apache_cordova_a8ebe81d92.svg",
        },
        {
          name: "MariaDB",
          icon: "https://invozone-backend.s3.amazonaws.com/mariadb_478f735ed2.svg",
        },
        {
          name: "MSSQL",
          icon: "https://invozone-backend.s3.amazonaws.com/microsoft_sql_server_dd66629fd4.svg",
        },
        {
          name: "Postgres",
          icon: "https://invozone-backend.s3.amazonaws.com/postgresql_2356a618ea.svg",
        },
        {
          name: "MongoDB",
          icon: "https://invozone-backend.s3.amazonaws.com/mongodb_49ad80646d.svg",
        },
        {
          name: "Kubernetes",
          icon: "https://invozone-backend.s3.amazonaws.com/kubernetes_17fcfbf804.svg",
        },
        {
          name: "Docker",
          icon: "https://invozone-backend.s3.amazonaws.com/docker_aef732bbc3.svg",
        },
      ],
    },
  ]

  const [activeAccordion, setActiveAccordion] = useState(`${data[0]?.title}`)
  return (
    <div className="techMobileCMS">
      <div className={styles.techStacksMobileCMS}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Accordion defaultActiveKey={`${data[0]?.title}`}>
            {data &&
              data?.map((e, i) => (
                <Card className="" key={i}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={`${e?.title}`}
                    className={`TechStacksMObileCmsAccord ${
                      e?.title === activeAccordion ? "active-accordion" : ""
                    } ${
                      (i === 1 || i === 3 || i === 5) &&
                      e?.title !== activeAccordion
                        ? "second-accordion-web-cms"
                        : (i === 0 || i === 2 || i === 4) &&
                          e?.title !== activeAccordion
                        ? "first-accordion-web-cms"
                        : ""
                    }`}
                    onClick={() => setActiveAccordion(e?.title)}
                    style={
                      i === 1 && e?.title === activeAccordion
                        ? { marginTop: "24px" }
                        : {}
                    }
                  >
                    {e?.title}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${e?.title}`}>
                    <Card.Body className={`cardBody`}>
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
          </Accordion>
        </Container>
      </div>
    </div>
  )
}

export default TechStacksMobile
