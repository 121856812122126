// extracted by mini-css-extract-plugin
export var crossImg = "Solutions-module--crossImg--f1081";
export var description = "Solutions-module--description--9e100";
export var description2 = "Solutions-module--description2--48e9a";
export var faqButton = "Solutions-module--faqButton--9bbcf";
export var faqsNum = "Solutions-module--faqsNum--f0ad0";
export var faqsSub = "Solutions-module--faqsSub--54c42";
export var head = "Solutions-module--head--18f9b";
export var headr = "Solutions-module--headr--b7291";
export var quesColor = "Solutions-module--quesColor--2a39a";
export var quesColorSelected = "Solutions-module--quesColorSelected--b8897";
export var reverse = "Solutions-module--reverse--ba2ca";
export var sectionLatestTechnologyWebCMSDevelopment = "Solutions-module--sectionLatestTechnologyWebCMSDevelopment--4531c";
export var serial = "Solutions-module--serial--c7415";
export var serial2 = "Solutions-module--serial2--81760";
export var textColor = "Solutions-module--textColor--e9e82";