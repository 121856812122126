// extracted by mini-css-extract-plugin
export var GlobalTechStacksWebCms = "TechStacksCms-module--GlobalTechStacksWebCms--7553d";
export var cardBg = "TechStacksCms-module--cardBg--3fdde";
export var cards = "TechStacksCms-module--cards--3883d";
export var cardsContent = "TechStacksCms-module--cardsContent--37545";
export var description = "TechStacksCms-module--description--e23fa";
export var heading = "TechStacksCms-module--heading--f3f95";
export var iconssCard = "TechStacksCms-module--iconssCard--db859";
export var nav = "TechStacksCms-module--nav--3c7de";
export var navItem = "TechStacksCms-module--nav-item--4abb2";
export var navbarBlock = "TechStacksCms-module--navbarBlock--19066";
export var tabData = "TechStacksCms-module--tabData--455d0";
export var tech = "TechStacksCms-module--tech--31351";
export var techIcon = "TechStacksCms-module--techIcon--b3b34";
export var techImg = "TechStacksCms-module--techImg--96b9c";
export var technologyIcon = "TechStacksCms-module--technologyIcon--8d9ea";