// extracted by mini-css-extract-plugin
export var cardBg = "TechStacksMobile-module--cardBg--4c4d5";
export var cards = "TechStacksMobile-module--cards--bc971";
export var cardsContent = "TechStacksMobile-module--cardsContent--a18bf";
export var description = "TechStacksMobile-module--description--387ba";
export var heading = "TechStacksMobile-module--heading--14be4";
export var iconssCard = "TechStacksMobile-module--iconssCard--3dd58";
export var nav = "TechStacksMobile-module--nav--91fe4";
export var navItem = "TechStacksMobile-module--nav-item--cfd18";
export var navbarBlock = "TechStacksMobile-module--navbarBlock--4207d";
export var tabData = "TechStacksMobile-module--tabData--01486";
export var tech = "TechStacksMobile-module--tech--1669a";
export var techIcon = "TechStacksMobile-module--techIcon--de1a1";
export var techImg = "TechStacksMobile-module--techImg--dc72d";
export var techStacksMobileCMS = "TechStacksMobile-module--techStacksMobileCMS--c43e9";
export var technologyIcon = "TechStacksMobile-module--technologyIcon--e8b9e";