// extracted by mini-css-extract-plugin
export var banerImg = "Banner-module--banerImg--587ee";
export var bannerBtn = "Banner-module--bannerBtn--61358";
export var bannerHeading = "Banner-module--bannerHeading--9f54d";
export var bannerRight = "Banner-module--bannerRight--91ae6";
export var bannerWebCms = "Banner-module--bannerWebCms--4a175";
export var btn_white2_border = "Banner-module--btn_white2_border--82126";
export var cards = "Banner-module--cards--28a52";
export var head = "Banner-module--head--55f46";
export var heading = "Banner-module--heading--c955d";
export var subTitle = "Banner-module--subTitle--c57ad";
export var subTitle2 = "Banner-module--subTitle2--ddda5";
export var talent = "Banner-module--talent--ce8cf";
export var text = "Banner-module--text--b7258";
export var tick = "Banner-module--tick--d6060";